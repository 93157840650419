import React, { lazy, Suspense, memo } from 'react'
import { useSelector } from 'react-redux'
import Loading from 'components/shared-components/Loading';
import Views from 'views';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
	const token = useSelector(state => state.auth.token);
  const themeData = useSelector(state => state.theme);
	const blankLayout = themeData.blankLayout;
	const direction = themeData.direction;
	const navType = themeData.navType;
	const navCollapsed = themeData.navCollapsed;

	const Layout = token && !blankLayout ? AppLayout : AuthLayout

	return (
		<Suspense fallback={<Loading cover="content"/>}>
			<Layout direction={direction} navType={navType} navCollapsed={navCollapsed}>
				<Views />
			</Layout>
		</Suspense>
	)
}

export default memo(Layouts)
